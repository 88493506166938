import gql from "graphql-tag";

const tipo_nominasTableQuery = gql`
  query tipo_nominas(
    $whereConditions: TipoNominasWhereWhereConditions
    $numberItems: Int!
    $numberPage: Int!
    $fieldOrder: String!
    $Order: SortOrder!
  ) {
    tipo_nominas(
      where: $whereConditions
      first: $numberItems
      page: $numberPage
      activo: true
      orderBy: [{ field: $fieldOrder, order: $Order }]
    ) {
      data {
        id
        descripcion
        periodicidad_id
        cliente_id
        clave
        empresa_id
        cliente {
          nombre
        }
        empresa {
          nombre
        }
        periodicidad {
          clave
          descripcion
          inicio_vigencia
          final_vigencia
        }
        comentarios
        getCountPeriodos
        dias_quincena
        aplicar_ajuste_mensual
        aplicar_aguinaldo_reglamento
        precalculo_variables
        seguro_infonavit_parcial
        costo_social_dias_calendario
        aplicar_ajuste_subsidio_causado
        aplicar_ajuste_subsidio_entregado
        aplicar_reintegro_isr
        aplicar_ptu_reglamento
        calculo_septimo_dia
        retener_isr_salario_minimo
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        lastItem
        hasMorePages
        lastPage
        perPage
        total
      }
    }
  }
`;
const tipo_nominasQuery = gql`
  query tipo_nominas(
    $whereConditions: TipoNominasWhereWhereConditions
    $numberItems: Int!
    $numberPage: Int!
    $fieldOrder: String!
  ) {
    tipo_nominas(
      where: $whereConditions
      first: $numberItems
      page: $numberPage
      activo: true
      orderBy: [{ field: $fieldOrder, order: DESC }]
    ) {
      data {
        id
        periodicidad_id
        clave
        descripcion
        comentarios
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        lastItem
        hasMorePages
        lastPage
        perPage
        total
      }
    }
  }
`;

const queries = { tipo_nominasTableQuery, tipo_nominasQuery };

export default queries;
