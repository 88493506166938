<template>
  <div id="pageTable">
    <v-container
      class="d-flex align-center justify-center"
      style="height: 80vh; width: 80vw"
      v-if="
        rol != 'root' &&
        rol != 'admin' &&
        rol != 'admin-empresa' &&
        rol != 'admin-sucursal' &&
        rol != 'admin-depto'
      "
    >
      <v-card class="" style="border-radius: 20px" max-width="500">
        <v-card-title class="justify-center card_titulo">
          <div class="headerModal">
            <img :src="'/static/icon/peligro.svg'" class="imgModal" />
          </div>
        </v-card-title>
        <v-card-text class="titleModal text-center">
          Esta vista no esta disponible para este rol de usuario.
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-center card_accion">
          <v-btn class="btnGuardar" @click="redireccionar()"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    <v-container v-else grid-list-xl fluid>
      <v-row>
        <v-col cols="12" lg="12">
          <data-table
            ref="tabla"
            :tableName="'Tipos de nómina'"
            :columns="columns"
            :items="tableData.data"
            :paginationInfo="tableData.paginatorInfo"
            @pagination="getPaginationInfo"
            :showAdvancedFilters="true"
            @loadModalData="loadModalData"
            :filters="filters"
            @setFilters="setFilters"
            :perPage="[10, 25, 50, 100]"
            :loading="isLoading"
          >
            <template slot="actionButtonsLeft">
              <div class="botonAgregar">
                <v-btn
                  color="#004BAF"
                  class="btnAdd"
                  right
                  rounded
                  @click="abrirModal('add')"
                >
                  <v-icon dark class="mr-2">add</v-icon>Agregar
                  <div class="mr-4"></div>
                </v-btn>
              </div>
            </template>
            <template slot="filters">
              <template v-if="rol == 'root'">
                <v-col xs="12" sm="6" md="6" class="py-0">
                  <v-autocomplete
                    outlined
                    :items="clientesFilters"
                    item-text="nombre"
                    item-value="id"
                    label="Cliente"
                    persistent-hint
                    v-model="cliente_filter"
                  ></v-autocomplete>
                </v-col>
              </template>

              <template v-if="rol == 'root' || rol == 'admin'">
                <v-col xs="12" sm="6" md="6" class="py-0">
                  <v-autocomplete
                    outlined
                    :items="empresasFilters"
                    item-text="nombre"
                    item-value="id"
                    label="Empresa"
                    persistent-hint
                    v-model="empresa_filter"
                  ></v-autocomplete>
                </v-col>
              </template>
              <template
                v-if="
                  rol == 'root' ||
                  rol == 'admin' ||
                  rol == 'admin-empresa' ||
                  rol == 'admin-sucursal'
                "
              >
                <v-col
                  xs="12"
                  sm="12"
                  :md="
                    rol == 'admin-empresa' || rol == 'admin-sucursal' ? 12 : 6
                  "
                  class="py-0"
                >
                  <v-autocomplete
                    outlined
                    label="Periodicidad"
                    :items="periodicidades"
                    item-text="descripcion"
                    item-value="id"
                    persistent-hint
                    v-model="periodicidad_filter"
                  >
                  </v-autocomplete>
                </v-col>
              </template>
              <v-col xs="12" sm="12" md="12" class="py-0">
                <v-text-field
                  outlined
                  label="Descripción"
                  v-model="descripcion_filter"
                ></v-text-field>
              </v-col>
            </template>
            <tbody slot="body" slot-scope="{ data }">
              <tr :key="item.id" v-for="item in data">
                <td>
                  <div>{{ item.clave }}</div>
                </td>
                <td>
                  <div>{{ item.descripcion }}</div>
                </td>
                <td>
                  <div>{{ item.periodicidad.descripcion }}</div>
                </td>
                <td>
                  <div class="tblOpciones">
                    <!-- <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModalPeridos(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotones">description</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Listar periodos</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="vista_periodos(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotones">description</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Periodos</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Editar</span>
                                            </v-tooltip> -->

                    <v-menu
                      buttom
                      left
                      class="elevation-0 d-inline"
                      content-class="mt-0 menuClassAcciones"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          fab
                          dark
                          small
                          class="elevation-0"
                        >
                          <template v-for="(item, valor) in attrs">
                            <v-icon
                              :key="valor"
                              v-if="valor == 'aria-expanded' && item == 'false'"
                              class="pl-2"
                              color="#828282"
                              >more_vert</v-icon
                            >
                            <v-icon
                              :key="valor"
                              v-if="valor == 'aria-expanded' && item == 'true'"
                              class="pl-2"
                              color="#2DB9D1"
                              >more_vert</v-icon
                            >
                          </template>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="abrirModal('update', item)">
                          <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="vista_periodos(item)">
                          <v-list-item-title
                            >Periodos de pago</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          class="botonHover"
                          icon
                          fab
                          dark
                          small
                          @click="eliminar(item)"
                        >
                          <v-icon
                            class="tamIconoBoton invertirColorBotonesDelete"
                            >delete</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </tbody>
          </data-table>
        </v-col>
      </v-row>
      <template>
        <v-dialog v-model="dialog" persistent max-width="700px" scrollable>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
          </template>
          <perfect-scrollbar style="background: #fff">
            <v-card elevation="0">
              <ValidationObserver ref="form" v-slot="{ invalid }">
                <v-card-title class="justify-center card_titulo">
                  <div class="headerModal">
                    <img :src="imagen" class="imgModal" />
                    <h2 class="titleModal">{{ tituloModal }}</h2>
                  </div>
                </v-card-title>

                <v-card-text class="card_texto">
                  <div id="padre" v-show="isLoadingModal">
                    <div id="loading">
                      <v-progress-circular
                        indeterminate
                        :size="100"
                        :width="7"
                        color="blue"
                      ></v-progress-circular>
                    </div>
                  </div>

                  <v-container
                    v-show="!isLoadingModal"
                    grid-list-md
                    id="contenedor"
                  >
                    <v-divider class="divider"></v-divider>
                    <v-row>
                      <v-col
                        v-if="rol == 'root'"
                        cols="12"
                        xs="12"
                        sm="6"
                        md="6"
                        class="py-0"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Cliente"
                          rules="required"
                        >
                          <v-autocomplete
                            outlined
                            label="Cliente"
                            :items="clientes"
                            item-text="nombre"
                            item-value="id"
                            persistent-hint
                            v-model="cliente_value"
                            class="pa-0 ma-0"
                            required
                            :error-messages="errors"
                          >
                          </v-autocomplete>
                        </ValidationProvider>
                      </v-col>
                      <v-col
                        v-if="rol == 'root' || rol == 'admin'"
                        cols="12"
                        xs="12"
                        :sm="rol == 'root' ? '6' : '12'"
                        :md="rol == 'root' ? '6' : '12'"
                        class="py-0"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Empresa"
                          rules="required"
                        >
                          <v-autocomplete
                            outlined
                            label="Empresa"
                            :items="empresas"
                            item-text="nombre"
                            item-value="id"
                            persistent-hint
                            v-model="empresa_value"
                            class="pa-0 ma-0"
                            required
                            :error-messages="errors"
                          >
                          </v-autocomplete>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Periodicidad"
                          rules="required"
                        >
                          <v-autocomplete
                            outlined
                            label="Periodicidad"
                            :items="periodicidades"
                            item-text="descripcion"
                            item-value="id"
                            persistent-hint
                            v-model="periodicidad_id"
                            class="pa-0 ma-0"
                            required
                            :error-messages="errors"
                            :disabled="getCountPeriodos > 0"
                          >
                          </v-autocomplete>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" xs="12" sm="3" md="2" class="py-0">
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Clave"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            label="Clave"
                            class="pa-0 ma-0"
                            disabled
                            v-model="tipoNomina.clave"
                            :error-messages="errors"
                            required
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col
                        v-if="!disablePeriodicidad"
                        cols="12"
                        xs="12"
                        sm="3"
                        md="4"
                        class="py-0"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Días de pago"
                          rules="required"
                        >
                          <v-autocomplete
                            outlined
                            label="Días de pago"
                            :items="itemsDiasPago"
                            item-text="descripcion"
                            item-value="valor"
                            persistent-hint
                            v-model="dias_quincena"
                            class="pa-0 ma-0"
                            required
                            :error-messages="errors"
                            :disabled="getCountPeriodos > 0"
                          >
                          </v-autocomplete>
                        </ValidationProvider>
                      </v-col>
                      <v-col
                        v-else
                        cols="12"
                        xs="12"
                        sm="3"
                        md="4"
                        class="py-0"
                      >
                        <v-autocomplete
                          outlined
                          label="Días de pago"
                          :items="itemsDiasPago"
                          item-text="descripcion"
                          item-value="valor"
                          persistent-hint
                          v-model="dias_quincena"
                          class="pa-0 ma-0"
                          disabled
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                        <p class="tituloOtrasAcciones">Otras configuraciones</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                        <v-checkbox
                          class="pa-0 ma-0 checkboxVuetify"
                          v-model="tipoNomina.aplicar_ajuste_mensual"
                          label="Aplicar ajuste mensual de ISR"
                        ></v-checkbox>
                      </v-col>

                      <v-col
                        v-if="tipoNomina.aplicar_ajuste_mensual"
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        class="py-0 d-flex"
                      >
                        <v-checkbox
                          class="pa-0 ma-0 pl-7 checkboxVuetify"
                          v-model="tipoNomina.aplicar_ajuste_subsidio_causado"
                          label="¿Ajustar subsidio causado?"
                        ></v-checkbox>
                        <v-tooltip top class="pb-7">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="#96999A"
                              v-on="on"
                              slot="activator"
                              class="pb-7 pl-2 outlined_v_icon"
                              >info</v-icon
                            >
                          </template>
                          <span class="pb-7 textTooltip">
                            El ajuste se aplicará cuando al final del <br />
                            mes se obtenga un subsidio causado <br />
                            menor al que le correspondía.
                          </span>
                        </v-tooltip>
                      </v-col>

                     <!--  <v-col
                        v-if="tipoNomina.aplicar_ajuste_mensual"
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        class="py-0 d-flex"
                      >
                        <v-checkbox
                          class="pa-0 ma-0 pl-7 checkboxVuetify"
                          v-model="tipoNomina.aplicar_ajuste_subsidio_entregado"
                          label="¿Ajustar subsidio entregado?"
                        ></v-checkbox>
                        <v-tooltip top class="pb-7">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="#96999A"
                              v-on="on"
                              slot="activator"
                              class="pb-7 pl-2 outlined_v_icon"
                              >info</v-icon
                            >
                          </template>
                          <span class="pb-7 textTooltip">
                            El ajuste se aplicará cuando al final del mes <br />
                            se obtenga un subsidio entregado menor <br />
                            al que le correspondía.
                          </span>
                        </v-tooltip>
                      </v-col> -->

                      <v-col
                        v-if="tipoNomina.aplicar_ajuste_mensual"
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        class="py-0 d-flex"
                      >
                        <v-checkbox
                          class="pa-0 ma-0 pl-7 checkboxVuetify"
                          v-model="tipoNomina.aplicar_reintegro_isr"
                          label="¿Reintegrar ISR retenido en exceso?"
                        ></v-checkbox>
                        <v-tooltip top class="pb-7">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="#96999A"
                              v-on="on"
                              slot="activator"
                              class="pb-7 pl-2 outlined_v_icon"
                              >info</v-icon
                            >
                          </template>
                          <span class="pb-7 textTooltip">
                            El ajuste se aplicará cuando al final del mes <br />
                            se obtenga una retención de ISR mayor al que le
                            <br />
                            correspondía.
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        class="py-0 d-flex"
                      >
                        <v-checkbox
                          class="pa-0 ma-0 checkboxVuetify"
                          v-model="tipoNomina.aplicar_aguinaldo_reglamento"
                          label="Calcular ISR de aguinaldo de acuerdo al reglamento"
                        ></v-checkbox>
                        <v-tooltip top class="pb-7">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="#96999A"
                              v-on="on"
                              slot="activator"
                              class="pb-7 pl-2 outlined_v_icon"
                              >info</v-icon
                            >
                          </template>
                          <span class="pb-7 textTooltip">
                            El aguinaldo será calculado de acuerdo <br />
                            al Artículo 174° del Reglamento de la Ley <br />
                            del Impuesto sobre la Renta (ISR).
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        class="py-0 d-flex"
                      >
                        <v-checkbox
                          class="pa-0 ma-0 checkboxVuetify"
                          v-model="tipoNomina.aplicar_ptu_reglamento"
                          label="Calcular ISR de PTU de acuerdo al reglamento"
                        ></v-checkbox>
                        <v-tooltip top class="pb-7">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="#96999A"
                              v-on="on"
                              slot="activator"
                              class="pb-7 pl-2 outlined_v_icon"
                              >info</v-icon
                            >
                          </template>
                          <span class="pb-7 textTooltip">
                            El PTU será calculado de acuerdo al <br />
                            Artículo 174° del Reglamento de la Ley <br />
                            del Impuesto sobre la Renta (ISR).
                          </span>
                        </v-tooltip>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        class="py-0 d-flex"
                      >
                        <v-checkbox
                          class="pa-0 ma-0 checkboxVuetify"
                          v-model="tipoNomina.precalculo_variables"
                          label="Cálculo automático promedio de variables"
                        ></v-checkbox>
                        <v-tooltip top class="pb-7">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="#96999A"
                              v-on="on"
                              slot="activator"
                              class="pb-7 pl-2 outlined_v_icon"
                              >info</v-icon
                            >
                          </template>
                          <span class="pb-7 textTooltip">
                            Calculamos el promedio de las percepciones <br />
                            variables cada bimestre.
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        class="py-0 d-flex"
                      >
                        <v-checkbox
                          class="pa-0 ma-0 checkboxVuetify"
                          v-model="tipoNomina.seguro_infonavit_parcial"
                          label="Retención de seguro vivienda proporcional"
                        ></v-checkbox>
                        <v-tooltip top class="pb-7">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="#96999A"
                              v-on="on"
                              slot="activator"
                              class="pb-7 pl-2 outlined_v_icon"
                              >info</v-icon
                            >
                          </template>
                          <span class="pb-7 textTooltip">
                            En caso de no realizar el cálculo proporcional,
                            <br />
                            la retención sera al inicio del bimestre.
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col
                        v-if="showCostoSocial"
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        class="py-0 d-flex"
                      >
                        <v-checkbox
                          class="pa-0 ma-0 checkboxVuetify"
                          v-model="tipoNomina.costo_social_dias_calendario"
                          label="Calcular costo social con días calendario"
                        ></v-checkbox>
                        <v-tooltip top class="pb-7">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="#96999A"
                              v-on="on"
                              slot="activator"
                              class="pb-7 pl-2 outlined_v_icon"
                              >info</v-icon
                            >
                          </template>
                          <span class="pb-7 textTooltip">
                            En caso de no elegir esta opción, el cálculo se<br />
                            realizará con base a los días de pago.
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        class="py-0 d-flex"
                      >
                        <v-checkbox
                          class="pa-0 ma-0 checkboxVuetify"
                          v-model="tipoNomina.retener_isr_salario_minimo"
                          label="Retener ISR con salario mínimo"
                        ></v-checkbox>
                        <v-tooltip top class="pb-7">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="#96999A"
                              v-on="on"
                              slot="activator"
                              class="pb-7 pl-2 outlined_v_icon"
                              >info</v-icon
                            >
                          </template>
                          <span class="pb-7 textTooltip">
                            Se aplica la retención de ISR <br />
                            cuando el empleado tenga salario mínimo
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col
                        v-if="periodicidad_id == 2"
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        class="py-0 d-flex"
                      >
                        <v-checkbox
                          class="pa-0 ma-0 checkboxVuetify"
                          v-model="tipoNomina.calculo_septimo_dia"
                          label="Cálcular proporción del séptimo día"
                        ></v-checkbox>
                        <!-- <v-tooltip top class="pb-7">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="#96999A"
                              v-on="on"
                              slot="activator"
                              class="pb-7 pl-2 outlined_v_icon"
                              >info</v-icon
                            >
                          </template>
                          <span class="pb-7 textTooltip">
                            Explicación pendiente
                          </span>
                        </v-tooltip> -->
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Descripcion"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            label="Descripción"
                            class="pa-0 ma-0"
                            v-model="tipoNomina.descripcion"
                            :error-messages="errors"
                            required
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                        <v-text-field
                          outlined
                          label="Comentarios"
                          class="pa-0 ma-0"
                          v-model="tipoNomina.comentarios"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="d-flex justify-end card_accion">
                  <button
                    class="btnCerrar"
                    @click="cerrarModal()"
                    :disabled="isSaving"
                  >
                    Cancelar
                  </button>
                  <v-btn
                    class="btnGuardar"
                    @click="guardar()"
                    :disabled="invalid"
                    :loading="isSaving"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </ValidationObserver>
            </v-card>
          </perfect-scrollbar>
        </v-dialog>
      </template>

      <!-- Cambiar a periodos -->
      <template class="borde-card">
        <v-dialog
          v-model="dialogPeriodos"
          persistent
          max-width="1100px"
          class="borde-card"
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="botonModal" id="btnModalPeriodos"></v-btn>
          </template>
          <v-card class="borde-card">
            <ValidationObserver ref="form" v-slot="{ invalid }">
              <v-card-title class="justify-center card_titulo">
                <div class="headerModal">
                  <img :src="imagen" class="imgModal" />
                  <h2 class="titleModal">{{ tituloModal }}</h2>
                </div>
              </v-card-title>

              <v-card-text class="card_texto">
                <div id="padre" v-show="isLoadingModalPeriodos">
                  <div id="loading">
                    <v-progress-circular
                      indeterminate
                      :size="100"
                      :width="7"
                      color="blue"
                    ></v-progress-circular>
                  </div>
                </div>

                <v-container
                  v-show="!isLoadingModalPeriodos"
                  grid-list-md
                  id="contenedor"
                >
                  <v-divider class="divider"></v-divider>

                  <table class="tbl-incidencias">
                    <thead class="tbl-header">
                      <tr>
                        <th rowspan="2" class="pl-2 firstth">Periodo</th>
                        <th rowspan="2">Fecha inicio</th>
                        <th rowspan="2">Fecha fin</th>
                        <th rowspan="2">Fecha pago</th>
                        <th rowspan="2">Mes de acumulación</th>
                        <th colspan="2" class="thTitulos">Mes</th>
                        <th colspan="2" class="thTitulos">Bimestre</th>
                        <th colspan="2" class="lastth thTitulos">Año</th>
                      </tr>
                      <tr>
                        <th class="thCheck">Inicio</th>
                        <th class="thCheck">Fin</th>
                        <th class="thCheck">Inicio</th>
                        <th class="thCheck">Fin</th>
                        <th class="thCheck">Inicio</th>
                        <th class="thCheck">Fin</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="datosTabla.length == 0">
                        <td class="emptyTableComplementos" colspan="11">
                          No hay elementos para mostrar
                        </td>
                      </tr>
                      <template v-else v-for="(periodo, index) in datosTabla">
                        <tr :key="index">
                          <td>
                            <span class="pl-2">
                              {{ periodo.numero }}
                            </span>
                          </td>
                          <td>
                            <VuetifyDatePicker
                              outlined
                              rules="required"
                              :valorDefecto="periodo.fecha_inicial"
                              v-model="periodo.fecha_inicial"
                              label=""
                              placeholder="dd/mm/aaaa"
                              dense
                              hideDetails
                              :disabled="periodo.cerrado"
                            />
                          </td>
                          <td>
                            <VuetifyDatePicker
                              outlined
                              rules="required"
                              :valorDefecto="periodo.fecha_final"
                              v-model="periodo.fecha_final"
                              label=""
                              placeholder="dd/mm/aaaa"
                              dense
                              hideDetails
                              :disabled="periodo.cerrado"
                            />
                          </td>
                          <td>
                            <VuetifyDatePicker
                              outlined
                              rules="required"
                              :valorDefecto="periodo.fecha_de_pago"
                              v-model="periodo.fecha_de_pago"
                              label=""
                              placeholder="dd/mm/aaaa"
                              dense
                              hideDetails
                              :disabled="periodo.cerrado"
                            />
                          </td>
                          <td>
                            <v-autocomplete
                              outlined
                              dense
                              :items="meses"
                              item-text="nombre"
                              item-value="nombre"
                              persistent-hint
                              v-model="periodo.mes_de_acomulacion"
                              hide-details
                              :disabled="periodo.cerrado"
                            >
                            </v-autocomplete>
                          </td>
                          <td>
                            <v-checkbox
                              v-model="periodo.inicio_de_mes"
                              hide-details
                              :disabled="periodo.cerrado"
                              class="mt-0 pt-0"
                            ></v-checkbox>
                          </td>
                          <td>
                            <v-checkbox
                              v-model="periodo.fin_de_mes"
                              hide-details
                              :disabled="periodo.cerrado"
                              class="mt-0 pt-0"
                            ></v-checkbox>
                          </td>
                          <td>
                            <v-checkbox
                              v-model="periodo.inicio_de_bimestre"
                              hide-details
                              :disabled="periodo.cerrado"
                              class="mt-0 pt-0"
                            ></v-checkbox>
                          </td>
                          <td>
                            <v-checkbox
                              v-model="periodo.fin_de_bimestre"
                              hide-details
                              :disabled="periodo.cerrado"
                              class="mt-0 pt-0"
                            ></v-checkbox>
                          </td>
                          <td>
                            <v-checkbox
                              v-model="periodo.inicio_de_anio"
                              hide-details
                              :disabled="periodo.cerrado"
                              class="mt-0 pt-0"
                            ></v-checkbox>
                          </td>
                          <td>
                            <v-checkbox
                              v-model="periodo.fin_de_anio"
                              hide-details
                              :disabled="periodo.cerrado"
                              class="mt-0 pt-0"
                            ></v-checkbox>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </v-container>
              </v-card-text>
              <v-card-actions class="d-flex justify-end card_accion">
                <button
                  class="btnCerrar"
                  @click="cerrarModal()"
                  :disabled="isSaving"
                >
                  Cancelar
                </button>
                <v-btn
                  class="btnGuardar"
                  @click="guardarPeriodos()"
                  :disabled="invalid"
                  :loading="isSaving"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </template>
    </v-container>
  </div>
</template>

<script>
  import Datatable from "@/components/g-datatable/Datatable.vue";
  import queries from "@/queries/TipoNominas";
  import clienteAPi from "@/api/clientes";
  import empresaApi from "@/api/empresas";
  import sucursalApi from "@/api/sucursales";
  import Notify from "@/plugins/notify";
  import apiTipoNomina from "@/api/nominas/tipoNomina";
  import apiPeriodicidad from "@/api/nominas/periodicidadPago";
  import VuetifyDatePicker from "@/components/VuetifyDatePicker.vue";

  export default {
    components: {
      "data-table": Datatable,
      VuetifyDatePicker,
    },
    data() {
      return {
        columns: [
          {
            label: "Clave",
            name: "clave",
            filterable: true,
          },
          {
            label: "Descripción",
            name: "descripcion",
            filterable: true,
          },
          {
            label: "Periodicidad",
            name: "periodicidad_id",
            filterable: true,
          },
          {
            label: "Opciones",
            name: "opciones",
            align: "center",
            filterable: false,
          },
        ],
        filters: { AND: [] },
        tableData: {},
        isLoading: true,
        paginationData: {
          numberItems: 10,
          numberPage: 1,
        },
        tipoNomina: {
          id: null,
          cliente_id: null,
          empresa_id: null,
          periodicidad_id: null,
          clave: "",
          descripcion: "",
          comentarios: "",
          aplicar_ajuste_mensual: false,
          aplicar_aguinaldo_reglamento: false,
          aplicar_ptu_reglamento: false,
          dias_quincena: null,
          precalculo_variables: false,
          seguro_infonavit_parcial: false,
          costo_social_dias_calendario: false,
          aplicar_ajuste_subsidio_causado: false,
          aplicar_ajuste_subsidio_entregado: false,
          aplicar_reintegro_isr: false,
          calculo_septimo_dia: false,
          retener_isr_salario_minimo: false,
        },
        userData: this.$session.get("usuario"),
        rol: null,
        datosLogin: null,
        accion: null,
        tituloModal: null,
        dialog: false,
        isSaving: false,
        clientes: [],
        empresas: [],
        sucursales: [],
        cliente_value: null,
        empresa_value: null,
        sucursal_value: null,
        descripcion: null,
        periodicidad_id: null,
        periodicidades: [],
        imagen: "/static/icon/catalogos.png",
        periodicidad_update: null,
        clave_update: null,
        getCountPeriodos: null,
        cliente_filter: null,
        empresa_filter: null,
        periodicidad_filter: null,
        descripcion_filter: null,
        clientesFilters: [],
        empresasFilters: [],
        isLoadingModal: false,
        itemsDiasPago: [
          { id: 1, descripcion: "15 días", valor: "15" },
          { id: 2, descripcion: "15.20 días", valor: "15.20" },
          { id: 3, descripcion: "Días de calendario", valor: "CALENDARIO" },
        ],
        dias_quincena: null,
        periodicidad: null,
        disablePeriodicidad: true,
        showCostoSocial: false,
        dialogPeriodos: false,
        isLoadingModalPeriodos: false,
        datosTabla: [],
        meses: [
          { nombre: "Enero", id: 0 },
          { nombre: "Febrero", id: 1 },
          { nombre: "Marzo", id: 2 },
          { nombre: "Abril", id: 3 },
          { nombre: "Mayo", id: 4 },
          { nombre: "Junio", id: 5 },
          { nombre: "Julio", id: 6 },
          { nombre: "Agosto", id: 7 },
          { nombre: "Septiembre", id: 8 },
          { nombre: "Octubre", id: 9 },
          { nombre: "Noviembre", id: 10 },
          { nombre: "Diciembre", id: 11 },
        ],
      };
    },
    watch: {
      cliente_value: function (val) {
        //if(this.rol == "root" || this.rol == "admin")
        if (val != null) {
          this.cambiaCliente(val);
        }
      },
      cliente_filter: function (val) {
        if (val != null) {
          this.cambiaCliente(val);
        }
      },
      periodicidad_id: function (val) {
        if (
          this.cliente_value != null &&
          this.empresa_value != null &&
          val != null
        ) {
          if (
            this.accion == "add" ||
            (this.accion == "update" &&
              this.periodicidad_update != this.periodicidad_id)
          ) {
            this.getCodigoTipoNomina(val);
          }
        }
        if (this.accion == "update" && val != null) {
          if (this.periodicidad_update === val) {
            this.tipoNomina.clave = this.clave_update;
          }
        }

        if (val != null) {
          this.periodicidad = this.periodicidades.find(
            (item) => item.id == val
          );
          if (this.periodicidad.clave == "04") {
            //periodo quincenal
            this.disablePeriodicidad = false;
          } else {
            this.disablePeriodicidad = true;
            this.dias_quincena = null;
          }
        } else {
          this.periodicidad == null;
          this.disablePeriodicidad = true;
          this.dias_quincena = null;
        }
      },
      dias_quincena: function (val) {
        if (val == "15" || val == "15.20") {
          this.showCostoSocial = true;
        } else {
          this.showCostoSocial = false;
          this.tipoNomina.costo_social_dias_calendario = false;
        }
      },
      "tipoNomina.aplicar_ajuste_mensual": function (val) {
        if (val == false) {
          this.tipoNomina.aplicar_ajuste_subsidio_causado = false;
          this.tipoNomina.aplicar_ajuste_subsidio_entregado = false;
          this.tipoNomina.aplicar_reintegro_isr = false;
        }
      },
    },
    methods: {
      async listar() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;

        await this.getPeriodicidades();

        if (this.rol == "root") {
          this.getClientes();
        } else if (this.rol == "admin") {
          this.cliente_value = this.datosLogin.cliente_id;
          this.filters = {
            AND: [{ column: "CLIENTE_ID", value: this.datosLogin.cliente_id }],
          };
        } else {
          this.cliente_value = this.datosLogin.cliente_id;
          this.empresa_value = this.datosLogin.empresa_id;
          this.filters = {
            AND: [
              { column: "CLIENTE_ID", value: this.datosLogin.cliente_id },
              { column: "EMPRESA_ID", value: this.datosLogin.empresa_id },
            ],
          };
        }
      },
      getPaginationInfo(data) {
        switch (data.type) {
          case "page":
            this.paginationData.numberPage = data.value;
            break;

          case "itemsToShow":
            this.paginationData.numberItems = data.value;
            break;
        }
      },
      async abrirModal(accion, data = null) {
        await this.resetValues();
        this.accion = accion;
        this.isLoadingModal = true;

        if (this.rol == "root") {
          this.getClientes();
        } else if (this.rol == "admin") {
          this.cliente_value = this.datosLogin.cliente_id;
        } else {
          this.cliente_value = this.datosLogin.cliente_id;
          this.empresa_value = this.datosLogin.empresa_id;
        }
        this.getPeriodicidades();
        if (accion == "add") {
          this.tituloModal = "Registrar tipo de nómina";
          this.imagen = "/static/modal/tipoNominaCreate.svg";
          this.isLoadingModal = false;
        } else {
          this.tituloModal = "Actualizar tipo de nómina";
          this.imagen = "/static/modal/tipoNominaUpdate.svg";
          this.tipoNomina.id = data.id;
          this.tipoNomina.cliente_id = data.cliente_id;
          this.tipoNomina.empresa_id = data.empresa_id;
          this.cliente_value = data.cliente_id;
          this.empresa_value = data.empresa_id;
          this.tipoNomina.periodicidad_id = data.periodicidad_id;
          this.periodicidad_id = data.periodicidad_id;
          this.periodicidad_update = data.periodicidad_id;
          this.clave_update = data.clave;
          this.tipoNomina.clave = data.clave;
          this.tipoNomina.descripcion = data.descripcion;
          this.tipoNomina.comentarios = data.comentarios;
          this.getCountPeriodos = data.getCountPeriodos;
          this.tipoNomina.aplicar_ajuste_mensual = data.aplicar_ajuste_mensual;
          this.tipoNomina.aplicar_aguinaldo_reglamento =
            data.aplicar_aguinaldo_reglamento;
          this.tipoNomina.aplicar_ptu_reglamento = data.aplicar_ptu_reglamento;
          this.tipoNomina.precalculo_variables = data.precalculo_variables;
          this.dias_quincena = data.dias_quincena;
          this.tipoNomina.seguro_infonavit_parcial =
            data.seguro_infonavit_parcial;
          this.tipoNomina.costo_social_dias_calendario =
            data.costo_social_dias_calendario;
          this.tipoNomina.aplicar_ajuste_subsidio_causado =
            data.aplicar_ajuste_subsidio_causado;
          this.tipoNomina.aplicar_ajuste_subsidio_entregado =
            data.aplicar_ajuste_subsidio_entregado;
          this.tipoNomina.aplicar_reintegro_isr = data.aplicar_reintegro_isr;
          this.tipoNomina.calculo_septimo_dia = data.calculo_septimo_dia;
          this.tipoNomina.retener_isr_salario_minimo =
            data.retener_isr_salario_minimo;
          setTimeout(() => {
            this.isLoadingModal = false;
          }, 500);
        }
        setTimeout(function () {
          document.getElementById("btnModal").click();
        }, 100);
      },
      cerrarModal() {
        this.dialog = false;
        this.isSaving = false;
        this.dialogPeriodos = false;
        this.isLoadingModalPeriodos = false;
        this.resetValues();
      },
      resetValues() {
        this.tipoNomina = {
          id: null,
          cliente_id: null,
          empresa_id: null,
          periodicidad_id: null,
          clave: "",
          descripcion: "",
          comentarios: "",
          aplicar_ajuste_mensual: false,
          aplicar_aguinaldo_reglamento: false,
          aplicar_ptu_reglamento: false,
          dias_quincena: null,
          precalculo_variables: false,
          seguro_infonavit_parcial: false,
          aplicar_ajuste_subsidio_causado: false,
          aplicar_ajuste_subsidio_entregado: false,
          aplicar_reintegro_isr: false,
          calculo_septimo_dia: false,
          retener_isr_salario_minimo: false,
        };
        this.getCountPeriodos = null;
        this.showCostoSocial = false;
        this.datosTabla = [];

        this.$nextTick(() => {
          if (this.$refs.form != undefined || this.$refs.form != null) {
            this.$refs.form.reset();
          }
          if (this.rol == "root") {
            this.cliente_value = null;
            this.empresa_value = null;
            this.empresas = [];
            this.cliente_filter = null;
            this.empresa_filter = null;
            this.empresasFilters = [];
          }
          if (this.rol != "root") {
            this.empresa_value = null;
            this.empresa_filter = null;
          }

          this.descripcion_filter = null;
          this.periodicidad_filter = null;
          this.periodicidad_id = null;
          this.descripcion = null;
        });
      },
      async loadModalData() {
        await this.resetValues();
        if (this.rol == "root") {
          this.getClientes();
        } else if (this.rol == "admin") {
          this.cliente_filter = this.datosLogin.cliente_id;
        } else {
          this.cliente_filter = this.datosLogin.cliente_id;
          this.empresa_filter = this.datosLogin.empresa_id;
        }
        this.$apollo.queries.tipo_nominas.skip = true;
      },
      setFilters() {
        this.filters = { AND: [] };
        let descripcion = this.descripcion_filter;
        let cliente = this.cliente_filter;
        let empresa = this.empresa_filter;
        let periodicidad = this.periodicidad_filter;

        this.isLoading = true;

        if (
          descripcion != null &&
          descripcion != undefined &&
          descripcion != ""
        ) {
          this.filters.AND.push({
            column: "DESCRIPCION",
            operator: "ILIKE",
            value: "%" + descripcion + "%",
          });
        }

        if (cliente != null && cliente != undefined && cliente != "") {
          this.filters.AND.push({ column: "CLIENTE_ID", value: cliente });
        }

        if (empresa != null && empresa != undefined && empresa != "") {
          this.filters.AND.push({ column: "EMPRESA_ID", value: empresa });
        }

        if (
          periodicidad != null &&
          periodicidad != undefined &&
          periodicidad != ""
        ) {
          this.filters.AND.push({
            column: "PERIODICIDAD_ID",
            value: periodicidad,
          });
        }

        this.paginationData.numberPage = 1;
        this.$apollo.queries.tipo_nominas.skip = false;
        this.resetValues();
      },
      eliminar(data) {
        Notify.Alert(
          "¿Estás seguro de eliminar este tipo de nómina?",
          "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
          "Eliminar",
          () => {
            this.isLoading = true;
            apiTipoNomina
              .delete(data.id)
              .then((response) => {
                Notify.Success(
                  "Tipo de nomina eliminado",
                  "El tipo de Nómina ha sido eliminado satisfactoriamente"
                );
                this.$apollo.queries.tipo_nominas.refetch();
              })
              .catch((err) => {
                this.isLoading = false;
                if (typeof err.response.data.error === "string") {
                  Notify.ErrorToast(err.response.data.error);
                } else {
                  let error = Object.values(err.response.data.error);
                  let leyenda = ``;

                  for (var i = 0; i < error.length; i++) {
                    leyenda += `* ` + error[i] + `\n`;
                  }

                  Notify.ErrorToast(leyenda);
                }
              });
          },
          null,
          true,
          true
        );
      },
      getClientes(cliente = null) {
        let param = { activo: true, paginate: false };
        clienteAPi
          .getClientes(param)
          .then((response) => {
            this.clientesFilters = this.clientes = response.data;
          })
          .catch((error) => {
            this.resetValues();
            Notify.ErrorToast(error.response.data.error);
          });
      },
      cambiaCliente(id, cliente = null) {
        if (this.tipoNomina.cliente_id != id) {
          this.empresa_value = null;
          this.tipoNomina.empresa_id = null;
        }

        if (id == null) {
          return;
        }

        let parametros = { activo: true, paginate: false, cliente_id: id };
        empresaApi
          .getEmpresas(parametros)
          .then((response) => {
            this.empresasFilters = this.empresas = response.data;
            if (this.tipoNomina.empresa_id != null) {
              //this.empresa_value = this.tipoNomina.empresa_id;
            }
          })
          .catch((error) => {
            this.resetValues();
            Notify.ErrorToast(error.response.data.error);
          });
      },
      guardar() {
        this.$refs.form.validate().then((success) => {
          this.isSaving = true;
          if (!success) {
            this.isSaving = false;
            return;
          }
          this.tipoNomina.cliente_id = this.cliente_value;
          this.tipoNomina.empresa_id = this.empresa_value;
          this.tipoNomina.periodicidad_id = this.periodicidad_id;
          this.tipoNomina.dias_quincena = this.dias_quincena;
          this.tipoNomina.aplicar_ajuste_subsidio_entregado = false

          this.isLoading = true;
          if (this.accion === "add") {
            apiTipoNomina
              .store(this.tipoNomina)
              .then((response) => {
                Notify.Success(
                  "Operación exitosa",
                  "El Tipo de Nómina se guardó satisfactoriamente."
                );
                this.$apollo.queries.tipo_nominas.refetch();
                this.cerrarModal();
                this.$nextTick(() => {
                  this.$refs.form.reset();
                });
              })
              .catch((err) => {
                this.isSaving = false;
                this.isLoading = false;
                if (typeof err.response.data.error === "string") {
                  Notify.ErrorToast(err.response.data.error);
                } else {
                  let error = Object.values(err.response.data.error);
                  let leyenda = ``;

                  for (var i = 0; i < error.length; i++) {
                    leyenda += `* ` + error[i] + `\n`;
                  }
                  Notify.ErrorToast(leyenda);
                }
              });
          } else if (this.accion === "update") {
            apiTipoNomina
              .update(this.tipoNomina)
              .then((response) => {
                Notify.Success(
                  "Operación exitosa",
                  "El Tipo de Nomina se actualizó satisfactoriamente."
                );
                this.$apollo.queries.tipo_nominas.refetch();
                this.cerrarModal();
                this.$nextTick(() => {
                  this.$refs.form.reset();
                });
              })
              .catch((err) => {
                this.isSaving = false;
                this.isLoading = false;
                if (typeof err.response.data.error === "string") {
                  Notify.ErrorToast(err.response.data.error);
                } else {
                  let error = Object.values(err.response.data.error);
                  let leyenda = ``;

                  for (var i = 0; i < error.length; i++) {
                    leyenda += `* ` + error[i] + `\n`;
                  }
                  Notify.ErrorToast(leyenda);
                }
              });
          }
        });
      },
      getPeriodicidades() {
        let parametros = {
          activo: true,
          paginate: false,
          order: "asc",
        };
        apiPeriodicidad
          .find(parametros)
          .then((response) => {
            this.periodicidades = response.data;
          })
          .catch((err) => {
            let error = Object.values(err.response.data.error);
            let leyenda = ``;

            for (var i = 0; i < error.length; i++) {
              leyenda += `* ` + error[i] + `\n`;
            }

            Notify.ErrorToast(leyenda);
          });
      },
      getCodigoTipoNomina() {
        let parametros = {
          cliente_id: this.cliente_value,
          empresa_id: this.empresa_value,
          periodicidad_id: this.periodicidad_id,
        };
        apiTipoNomina
          .codigoTipoNomina(parametros)
          .then((response) => {
            this.tipoNomina.clave = response.data;
          })
          .catch((err) => {
            let error = Object.values(err.response.data.error);
            let leyenda = ``;

            for (var i = 0; i < error.length; i++) {
              leyenda += `* ` + error[i] + `\n`;
            }

            Notify.ErrorToast(leyenda);
          });
      },
      async abrirModalPeridos(item) {
        this.isLoadingModalPeriodos = true;
        this.imagen = "/static/modal/brigadasUpdate.svg";

        let parametros = { tipo_nomina_id: item.id };
        await apiTipoNomina
          .getPeriodos(parametros)
          .then((response) => {
            this.tituloModal = "Ejercicio " + response.anoFiscal.nombre;
            this.datosTabla = response.periodos;
            this.dialogPeriodos = true;
          })
          .catch((err) => {
            this.isLoadingModalPeriodos = false;
            this.dialogPeriodos = false;
            if (typeof err.response.data.error === "string") {
              Notify.ErrorToast(err.response.data.error);
            } else {
              let error = Object.values(err.response.data.error);
              let leyenda = ``;

              for (var i = 0; i < error.length; i++) {
                leyenda += `* ` + error[i] + `\n`;
              }
              Notify.ErrorToast(leyenda);
            }
          });
        this.isLoadingModalPeriodos = false;
      },
      guardarPeriodos() {
        this.$refs.form.validate().then((success) => {
          this.isSaving = true;
          if (!success) {
            this.isSaving = false;
            return;
          }
          let periodos = { periodos: this.datosTabla };
          apiTipoNomina
            .storePeridos(periodos)
            .then((response) => {
              Notify.Success(
                "Operación exitosa",
                "La configuración se guardó satisfactoriamente."
              );
              this.cerrarModal();
              this.$nextTick(() => {
                this.$refs.form.reset();
              });
            })
            .catch((err) => {
              this.isSaving = false;
              if (typeof err.response.data.error === "string") {
                Notify.ErrorToast(err.response.data.error);
              } else {
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                  leyenda += `* ` + error[i] + `\n`;
                }
                Notify.ErrorToast(leyenda);
              }
            });
        });
      },
      vista_periodos(data) {
        this.$session.remove("periodos");
        this.$session.set("periodos", data);
        this.$router.push("/periodos");
      },
      redireccionar() {
        this.$router.push("/dashboard");
      },
    },

    async created() {
      this.datosLogin = this.$session.get("usuario");
      this.rol = this.datosLogin.rol.name;

      await this.getPeriodicidades();

      if (this.rol == "root") {
        this.getClientes();
      } else if (this.rol == "admin") {
        this.cliente_value = this.datosLogin.cliente_id;
        this.filters = {
          AND: [{ column: "CLIENTE_ID", value: this.datosLogin.cliente_id }],
        };
      } else {
        this.cliente_value = this.datosLogin.cliente_id;
        this.empresa_value = this.datosLogin.empresa_id;
        this.filters = {
          AND: [
            { column: "CLIENTE_ID", value: this.datosLogin.cliente_id },
            { column: "EMPRESA_ID", value: this.datosLogin.empresa_id },
          ],
        };
      }
    },
    mounted() {
      this.$apollo.queries.tipo_nominas.skip = false;
    },
    apollo: {
      tipo_nominas: {
        query: queries.tipo_nominasTableQuery,
        fetchPolicy: "network-only",
        variables() {
          this.isLoading = true;
          return {
            whereConditions: this.filters,
            numberItems: this.paginationData.numberItems,
            numberPage: this.paginationData.numberPage,
            fieldOrder: this.$refs.tabla.sortKey
              ? this.$refs.tabla.sortKey
              : "id",
            Order: this.$refs.tabla.Order,
          };
        },
        skip() {
          return true;
        },
        result({ loading, error }) {
          this.isLoading = false;
          this.tableData = this.tipo_nominas;
        },
      },
    },
  };
</script>

<style scoped>
  #padre {
    padding-left: 40%;
  }
  #loading {
    display: table-cell;
    vertical-align: middle;
  }
  .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    display: none;
  }
  .tituloOtrasAcciones {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #828282;
  }

  /**
     * TABLE
     */
  th {
    color: #828282;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    padding: 8px;
    text-align: left;
  }

  .tbl-incidencias tbody td {
    color: #1e2144;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    padding: 2px;
    text-align: left;
  }

  .tbl-incidencias {
    border-collapse: collapse;
    width: 100%;
    margin-top: 15px;
    overflow-x: scroll;
    overflow-y: scroll;
  }

  .tbl-incidencias .tbl-header {
    background: #f6f6f6;
    border-radius: 10px;
    height: 58px;
  }
  .firstth {
    border-top-left-radius: 20px !important;
  }
  .lastth {
    border-top-right-radius: 20px !important;
  }
  .emptyTableComplementos {
    color: #c5c5c5 !important;
    font-family: "Montserrat" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    padding-bottom: 50px !important;
    padding-top: 50px !important;
    text-align: center !important;
  }
  .thCheck {
    min-width: 56px;
  }
  .thTitulos {
    background: #cfcfcf;
  }
  .menuClassAcciones {
    border-radius: 10px !important;
  }
</style>
